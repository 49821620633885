import React from "react";
import styled from "styled-components";
import BgImage from "../assets/images/backgrounds/robotics.jpg";
import { Helmet } from "react-helmet";

const Container = styled.div`
  .header {
    background: url(${BgImage});
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
  .sub-text {
    color: #8309f6;
  }
`;
const RoboticsLab = () => {
  return (
    <Container className=" p-0 m-0">
      <Helmet>
        <title>SSSRI - Robotics Laboratory</title>
      </Helmet>
      <div className="fixed w-full h-[76px] bg-blue-500 z-20"></div>
      <div className="header pt-40 flex justify-center items-center uppercase lg:h-[70vh] h-[60vh] lg:text-3xl">
        <div className="text-center">
          <h2 className=" font-extrabold uppercase lg:text-7xl md:text-7xl text-4xl text-primary ">
            Robotics Research
          </h2>

          <p className=" lg:text-9xl md:text-6xl text-xl text-primary">
            laboratory
          </p>
          <p className=" lg:text-sm md:text-3xl mt-10 px-20 font-extrabold text-white sub-text">
            Shaping the Future of Robotics Research
          </p>
        </div>
      </div>
      <div className="flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            The Robotics Laboratory is a leading robotics research laboratory
            committed to advancing the field of robotics and automation. Our
            mission is to drive innovation in robotics technology and its
            applications, shaping the future of robotics research.
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Our Mission and Goals</h1>
            <p>
              At Robotics Laboratory, our mission is to push the boundaries of
              robotics research. Our goals include:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Advancing robotics technology and design.</li>
                <li>Enhancing robotic systems for various applications.</li>
                <li>
                  Innovating in artificial intelligence and machine learning.
                </li>
                <li>Exploring new frontiers in robotics and automation.</li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Research Areas</h1>
            <p>Our laboratory specializes in several key research areas:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li className="">
                  <span className=" font-bold">Robotic Design: </span>We focus
                  on the development of innovative robotic systems, optimizing
                  their form and functionality.
                </li>
                <li>
                  <span className=" font-bold">Robotics Applications: </span>
                  Researching robotics applications in various fields, from
                  manufacturing to healthcare.
                </li>
                <li>
                  <span className=" font-bold">Artificial Intelligence:</span>
                  Exploring cutting-edge AI and machine learning solutions for
                  robotics.
                </li>
                <li>
                  <span className=" font-bold">Automation: </span>
                  Investigating automation solutions for industries and everyday
                  life.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Infrastructure and Resources
            </h1>
            <p>Our state-of-the-art facility boasts:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Advanced testing facilities for robotic systems and
                  components.
                </li>
                <li>
                  Prototyping and testing resources for innovative robot
                  designs.
                </li>
                <li>High-performance computing resources.</li>
                <li>
                  Specialized software tools for robotic design and analysis.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Collaborations</h1>
            <p className=" mt-5">
              Collaboration is central to our work. We partner with leading
              research institutions, industry experts, and other laboratories to
              drive innovation and tackle complex robotics challenges.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Achievements</h1>
            <p>
              Over the years, Aerospace Laboratory has achieved numerous
              milestones, including:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Technological breakthroughs.</li>
                <li>Publications in renowned robotics and AI journals.</li>
                <li>Successful robotics applications with global impact.</li>
              </ul>
            </p>
          </p>
        </div>
      </div>
      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Team and Expertise</h1>

            <p className=" mt-5">
              Our multidisciplinary team of experts brings together diverse
              talents, skills, and knowledge to tackle complex robotics
              challenges.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Educational Initiatives
            </h1>
            <p>As part of our commitment to knowledge sharing, we offer:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Opportunities for aspiring robotics researchers, including
                  internships and educational programs.
                </li>
                <li>Workshops, seminars, and robotics outreach programs.</li>
                <li>
                  Support for the next generation of robotics scientists and
                  engineers.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Impact on Society</h1>
            <p>
              The work done at Satellite systems Research and Exploration
              Laboratory has far-reaching effects, including:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Advancing automation and robotics in industry.</li>
                <li>Improving healthcare through robotic applications.</li>
                <li>Enabling scientific discoveries and space exploration.</li>
                <li>Contributing to the development of autonomous systems.</li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Future Directions</h1>
            <p>
              Our laboratory's future is filled with exciting prospects. We are
              poised to
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Launch new research projects that redefine robotics
                  technology.
                </li>
                <li>
                  Collaborate on innovative robotics applications in various
                  industries.
                </li>
                <li>
                  Continue making significant contributions to the field of
                  robotics research and automation.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Importance of Satellite Systems
            </h1>
            <p className=" mt-5">
              Robotics research is pivotal to the advancement of society,
              influencing how we live, work, and explore new frontiers. Robotics
              Laboratory is at the forefront of shaping the future of robotics
              and automation.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Questions and Engagement
            </h1>
            <p className=" mt-5">
              We welcome your questions and engagement. If you have inquiries
              about our research, projects, or collaboration opportunities,
              please don't hesitate to reach out to us.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Conclusion</h1>
            <p className=" mt-5">
              Thank you for exploring Robotics Laboratory. We invite you to
              learn more about our research and join us in our mission to shape
              the future of robotics research.
            </p>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default RoboticsLab;
