import React from "react";
import styled from "styled-components";
import { LazyLoadImage } from "react-lazy-load-image-component";

import KTU from "../../assets/images/partners/need/ktulogo.png";
import TTU from "../../assets/images/partners/need/ttulogo.png";
import UNER from "../../assets/images/partners/need/un_natural_resources.webp";
import UDS from "../../assets/images/partners/need/udslogo.webp";
import KNUST from "../../assets/images/partners/need/knustlogo.webp";
import STEM from "../../assets/images/partners/need/stem.webp";
import CPUT from "../../assets/images/partners/need/CPUT.png";
import Morgan from "../../assets/images/partners/need/morganlogo.png"; //https://www.morgan.edu/
import Nihon from "../../assets/images/partners/need/nihonlogo.webp";
import NUS from "../../assets/images/partners/need/nuslogo.webp"; //https://nus.edu.sg/
import NAU from "../../assets/images/partners/need/nanguilogo.png"; //https://www.univ-na.ci/
import EPA from "../../assets/images/partners/need/epa-logo.png"; //https://www.epa.gov.gh/epa/
import EPAUSA from "../../assets/images/partners/need/epausa.png"; //https://www.epa.gov/
import GSTI from "../../assets/images/partners/need/gssti-logo-trans.webp"; //https://gssti.org/
import AEROQUAL from "../../assets/images/partners/need/aeroqual.png"; //https://www.aeroqual.com/
import TOA from "../../assets/images/partners/need/TOA.webp"; //https://www.toaelectronics.com/
import ATL from "../../assets/images/partners/need/atel_logo.png"; //https://atelgh.com/
import AIRBUS from "../../assets/images/partners/need/airbuslogo.svg"; //https://www.airbus.com/en/space
import AZITO from "../../assets/images/partners/need/azito.svg";
import IQAir from "../../assets/images/partners/need/iqair.svg"; //https://www.iqair.com/switzerland

//"../../../assets/images/partners/need"

// Create a Logo component that displays an image of a logo inside a styled div
const Logo = ({ src, bgColor, alt }) => {
  // Create a styled div that wraps the image
  const LogoWrapper = styled.div`
    background: ${bgColor};
  `;

  // Return the Logo component
  return (
    <LogoWrapper className="m-1">
      <LazyLoadImage src={src} alt={alt} />
    </LogoWrapper>
  );
};

const Partners = styled.div`
  .bg-color-gray {
    background-color: #b7b7b7a6;
  }
`;
const SummaryValuedPartners = () => {
  const RectLogos = [
    { name: EPAUSA, alt: "EPAUSA" },
    { name: Nihon, alt: "Nihon" },
    { name: STEM, alt: "STEM" },
    { name: KNUST, alt: "KNUST" },
    { name: CPUT, alt: "CPUT", bg: "#3a3333" },
    { name: Morgan, alt: "Morgan", bg: "#3a3333" },
  ];
  const RectLogosMd = [
    { name: AZITO, alt: "AZITO" },
    { name: AIRBUS, alt: "AIRBUS", bg: "#232121" },
    { name: UDS, alt: "UDS", bg: "#3a3333" },
  ];
  const RectLogosLg = [
    { name: UNER, alt: "UNER", bg: "#13a3eb" },
    { name: KTU, alt: "KTU" },
    { name: TTU, alt: "TTU" },
    { name: AEROQUAL, alt: "AEROQUAL" },
  ];

  const OtherLogos = [
    { name: EPA, url: "" },
    { name: GSTI, url: "" },
    { name: TOA, url: "" },
    { name: NUS, url: "" },
    { name: NAU, url: "" },
    { name: ATL, url: "" },
    { name: IQAir, url: "" },
  ];

  // Return the LogoCarousel component
  return (
    <Partners className=" lg:pr-14 p-0">
      <div className="grid grid-cols-3 bg-color-gray">
        <div className="">
          {RectLogos.map((src) => (
            <Logo src={src.name} key={src} bgColor={src.bg} alt={src.alt} />
          ))}
        </div>

        <div className=" ">
          {RectLogosMd.map((src) => (
            <Logo src={src.name} key={src} bgColor={src.bg} alt={src.alt} />
          ))}

          <div className="grid grid-cols-3">
            {OtherLogos.map((src) => (
              <Logo src={src.name} key={src} bgColor={src.bg} alt={src.alt} />
            ))}
          </div>
        </div>
        <div className=" ">
          {RectLogosLg.map((src) => (
            <Logo src={src.name} key={src} bgColor={src.bg} alt={src.alt} />
          ))}
        </div>
      </div>
    </Partners>
  );
};

export default SummaryValuedPartners;
