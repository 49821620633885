import React from "react";
import styled from "styled-components";
import BgImage from "../assets/images/backgrounds/stemlab.webp";
import { Helmet } from "react-helmet";

const Container = styled.div`
  .header {
    background: url(${BgImage});
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
  .sub-text {
    background-color: white;
  }
`;
const STEMEducation = () => {
  return (
    <Container className=" p-0 m-0">
      <Helmet>
        <title>SSSRI - STEM Education</title>
      </Helmet>
      <div className="fixed w-full h-[76px] bg-blue-500 z-20"></div>
      <div className="header pt-40 flex justify-center items-center lg:h-[70vh] h-[70vh] lg:text-3xl">
        <div className="text-center">
          <div className="introduction flex justify-center pt-20">
            <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
              <p className=" lg:text-xl md:text-3xl text-md sub-text p-10">
                We are not just educators; we are visionaries and catalysts for
                the future. Our unwavering commitment to STEM education is
                driven by the profound belief that the foundation we lay today
                shapes the innovators, creators, and problem-solvers of
                tomorrow.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">About Us</h1>
            <p className=" mt-5">
              <p>
                <span className=" font-bold">Mission:</span>We strive to provide
                unparalleled STEM education, fostering a love for science,
                technology, engineering, and mathematics in every student who
                walks through our doors.
              </p>
              <p>
                <span className=" font-bold">Vision:</span>To be the driving
                force in creating a generation of forward-thinkers, equipped to
                solve the world's most pressing challenges.
              </p>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Curriculum and Programs
            </h1>
            <p>
              Our comprehensive STEM curriculum covers a wide spectrum of
              subjects, ensuring that learners of all ages and backgrounds find
              their niche. Our programs include:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  <span className=" font-bold">Science:</span> A journey into
                  the wonders of the natural world.
                </li>
                <li>
                  <span className=" font-bold">Technology:</span> Unleashing the
                  potential of modern tools and software.
                </li>
                <li>
                  <span className=" font-bold">Engineering:</span> Building,
                  tinkering, and solving real-world problems.
                </li>
                <li>
                  <span className=" font-bold">Mathematics:</span> Mastering the
                  language of the universe.
                </li>
              </ul>
              <div>
                In addition, we offer specialized programs such as robotics,
                coding bootcamps, and interdisciplinary STEM projects that
                prepare students for the rapidly evolving digital landscape.
              </div>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Qualified Staff</h1>
            <p className=" mt-5">
              Our dedicated team of instructors and staff brings a wealth of
              experience and expertise to the classroom. With backgrounds in
              academia, industry, and research, they are well-equipped to guide
              our students on their educational journeys. We also collaborate
              with leading experts and local educational institutions to provide
              an enriched learning environment.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Cutting-Edge Facilities and Resources
            </h1>
            <p className=" mt-5">
              In our state-of-the-art facilities, students have access to
              well-equipped laboratories, the latest technology, and extensive
              libraries. We believe that practical, hands-on experience is key
              to mastering STEM subjects, and our facilities reflect this
              philosophy.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Cutting-Edge Facilities and Resources
            </h1>
            <p className=" mt-5">
              At Space Science Systems Research institution, we embrace
              innovative teaching methods and stay at the forefront of
              educational trends. We understand that learning evolves, and we
              adapt to ensure our students receive the best education possible.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Student Success Stories
            </h1>
            <p className=" mt-5">
              Our students have consistently excelled in various contests,
              competitions, and have been awarded scholarships, proving that the
              future belongs to those who embark on a journey with us.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Partnerships and Collaborations
            </h1>
            <p className=" mt-5">
              We are proud to collaborate with local schools, businesses, and
              organizations that share our vision of promoting STEM education.
              These partnerships open doors to unique opportunities and
              experiences for our students.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Research Areas</h1>
            <p>Our laboratory specializes in several key research areas:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li className="">
                  <span className=" font-bold">Robotic Design: </span>We focus
                  on the development of innovative robotic systems, optimizing
                  their form and functionality.
                </li>
                <li>
                  <span className=" font-bold">Robotics Applications: </span>
                  Researching robotics applications in various fields, from
                  manufacturing to healthcare.
                </li>
                <li>
                  <span className=" font-bold">Artificial Intelligence:</span>
                  Exploring cutting-edge AI and machine learning solutions for
                  robotics.
                </li>
                <li>
                  <span className=" font-bold">Automation: </span>
                  Investigating automation solutions for industries and everyday
                  life.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Infrastructure and Resources
            </h1>
            <p>Our state-of-the-art facility boasts:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Advanced testing facilities for robotic systems and
                  components.
                </li>
                <li>
                  Prototyping and testing resources for innovative robot
                  designs.
                </li>
                <li>High-performance computing resources.</li>
                <li>
                  Specialized software tools for robotic design and analysis.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Collaborations</h1>
            <p className=" mt-5">
              Collaboration is central to our work. We partner with leading
              research institutions, industry experts, and other laboratories to
              drive innovation and tackle complex robotics challenges.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Achievements</h1>
            <p>
              Over the years, Aerospace Laboratory has achieved numerous
              milestones, including:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Technological breakthroughs.</li>
                <li>Publications in renowned robotics and AI journals.</li>
                <li>Successful robotics applications with global impact.</li>
              </ul>
            </p>
          </p>
        </div>
      </div>
      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Team and Expertise</h1>

            <p className=" mt-5">
              Our multidisciplinary team of experts brings together diverse
              talents, skills, and knowledge to tackle complex robotics
              challenges.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Educational Initiatives
            </h1>
            <p>As part of our commitment to knowledge sharing, we offer:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Opportunities for aspiring robotics researchers, including
                  internships and educational programs.
                </li>
                <li>Workshops, seminars, and robotics outreach programs.</li>
                <li>
                  Support for the next generation of robotics scientists and
                  engineers.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Impact on Society</h1>
            <p>
              The work done at Satellite systems Research and Exploration
              Laboratory has far-reaching effects, including:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Advancing automation and robotics in industry.</li>
                <li>Improving healthcare through robotic applications.</li>
                <li>Enabling scientific discoveries and space exploration.</li>
                <li>Contributing to the development of autonomous systems.</li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Future Directions</h1>
            <p>
              Our laboratory's future is filled with exciting prospects. We are
              poised to
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Launch new research projects that redefine robotics
                  technology.
                </li>
                <li>
                  Collaborate on innovative robotics applications in various
                  industries.
                </li>
                <li>
                  Continue making significant contributions to the field of
                  robotics research and automation.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Importance of Satellite Systems
            </h1>
            <p className=" mt-5">
              Robotics research is pivotal to the advancement of society,
              influencing how we live, work, and explore new frontiers. Robotics
              Laboratory is at the forefront of shaping the future of robotics
              and automation.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Questions and Engagement
            </h1>
            <p className=" mt-5">
              We welcome your questions and engagement. If you have inquiries
              about our research, projects, or collaboration opportunities,
              please don't hesitate to reach out to us.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Conclusion</h1>
            <p className=" mt-5">
              Thank you for exploring Robotics Laboratory. We invite you to
              learn more about our research and join us in our mission to shape
              the future of robotics research.
            </p>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default STEMEducation;
