import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BgImage from "../assets/images/backgrounds/aerospacelab.jpg";
import Helmet from "react-helmet";

const Container = styled.div`
  .header {
    background: url(${BgImage});
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
  .introduction {
  }
`;
const AerospaceLab = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the device is mobile
    const checkIsMobile = () => {
      // Use matchMedia to check if the screen width is less than a certain value
      const mobileMediaQuery = window.matchMedia("(max-width: 768px)");
      setIsMobile(mobileMediaQuery.matches);
    };

    // Initial check
    checkIsMobile();

    // Add event listener for changes in screen width
    window.addEventListener("resize", checkIsMobile);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  return (
    <Container className=" p-0 m-0">
      <Helmet>
        <title>SSSRI - Aerospace Laboratory</title>
      </Helmet>
      <div className="fixed w-full h-[76px] bg-blue-500 z-20"></div>
      {isMobile ? (
        <div className="flex justify-center items-center uppercase lg:text-3xl ">
          <div className=" mt-[76px] h-[50vh] relative">
            <div className=" absolute w-full h-full flex items-center justify-center z-10">
              <div className="text-center">
                <h2 className="font-extrabold uppercase lg:text-7xl md:text-7xl text-4xl text-primary">
                  aerospace Research
                </h2>
                <p className=" lg:text-9xl md:text-6xl text-2xl text-primary">
                  laboratory
                </p>
                <div className=" lg:text-sm md:text-3xl mt-5 px-10 font-bold text-white mx-4">
                  Pioneering the Future of Aerospace Research
                </div>
              </div>
            </div>
            <img src={BgImage} className="w-full h-full" alt="Nature" />
          </div>
        </div>
      ) : (
        <div className="header pt-40 flex justify-center items-center uppercase lg:h-[90vh] h-[90vh] lg:text-3xl">
          <div className="text-center">
            <h2 className=" font-extrabold uppercase lg:text-7xl md:text-7xl text-4xl text-primary">
              aerospace Research
            </h2>

            <p className=" lg:text-9xl md:text-6xl text-xl text-primary">
              laboratory
            </p>
            <p className=" lg:text-sm md:text-3xl mt-10 px-10 text-white font-bold">
              Pioneering the Future of Aerospace Research
            </p>
          </div>
        </div>
      )}

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            Welcome to our Aerospace Laboratory - a leading aerospace research
            laboratory dedicated to advancing the frontiers of aerospace
            technology and exploration. We are committed to pushing the
            boundaries of knowledge and technology to propel the aerospace
            industry forward.
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Our Mission and Goals</h1>
            <p>
              At Aerospace Laboratory, our mission is to drive innovation in
              aerospace research. Our goals include:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Advancing aerospace technology and design.</li>
                <li>
                  Improving propulsion systems for aircraft and spacecraft.
                </li>
                <li>Enhancing aerospace communication and navigation.</li>
                <li>Exploring new applications for aerospace technology.</li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Research Areas</h1>
            <p>Our laboratory specializes in several key research areas:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li className="">
                  <span className=" font-bold">Aerospace Design: </span>We focus
                  on the development of innovative aerospace vehicles,
                  optimizing their form and functionality.
                </li>
                <li>
                  <span className=" font-bold">Propulsion Systems:</span>
                  Researching advanced propulsion systems to improve the
                  mobility and efficiency of aircraft and spacecraft.
                </li>
                <li>
                  <span className=" font-bold">
                    Communication and Navigation:{" "}
                  </span>
                  Exploring cutting-edge communication and navigation systems
                  for aerospace applications.
                </li>
                <li>
                  <span className=" font-bold">Space Exploration:</span>{" "}
                  Investigating the possibilities of space exploration,
                  including missions to other celestial bodies.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Infrastructure and Resources
            </h1>
            <p>Our state-of-the-art facility boasts:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Advanced testing facilities for aerospace vehicles and
                  components.
                </li>
                <li>
                  Prototyping and testing resources for innovative aerospace
                  designs.
                </li>
                <li>High-performance computing resources.</li>
                <li>
                  Specialized software tools for aerospace design and analysis.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Collaborations</h1>
            <p className=" mt-5">
              Collaboration is at the core of our work. We partner with leading
              aerospace institutions, government agencies, and industry experts
              to drive innovation and tackle complex aerospace challenges.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Achievements</h1>
            <p>
              Over the years, Aerospace Laboratory has achieved numerous
              milestones, including:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Technological breakthroughs.</li>
                <li>Publications in prestigious journals.</li>
                <li>Successful satellite missions with global impact.</li>
              </ul>
            </p>
          </p>
        </div>
      </div>
      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Team and Expertise</h1>

            <p className=" mt-5">
              Our multidisciplinary team of experts brings together diverse
              talents, skills, and knowledge to tackle complex aerospace
              challenges.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Educational Initiatives
            </h1>
            <p>As part of our commitment to knowledge sharing, we offer:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Opportunities for aspiring aerospace researchers, including
                  internships and fellowships.
                </li>
                <li>Workshops and educational outreach programs.</li>
                <li>
                  Support for the next generation of aerospace scientists and
                  engineers.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Impact on Society</h1>
            <p>
              The work done at Satellite systems Research and Exploration
              Laboratory has far-reaching effects, including:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Advancing air travel and space exploration.</li>
                <li>Improving aerospace safety and efficiency.</li>
                <li>Enabling scientific discoveries through space missions.</li>
                <li>Contributing to national security and defense efforts.</li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Future Directions</h1>
            <p>
              Our laboratory's future is filled with exciting prospects. We are
              poised to
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Launch new research projects that redefine aerospace
                  technology.
                </li>
                <li>Collaborate on international aerospace missions.</li>
                <li>
                  Continue making significant contributions to the field of
                  aerospace research and exploration.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Importance of Satellite Systems
            </h1>
            <p className=" mt-5">
              Aerospace research is pivotal to the advancement of society,
              influencing how we travel, communicate, explore space, and ensure
              national security. Aerospace Laboratory is at the forefront of
              shaping the future of aerospace technology.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Questions and Engagement
            </h1>
            <p className=" mt-5">
              We welcome your questions and engagement. If you have inquiries
              about our research, projects, or collaboration opportunities,
              please don't hesitate to reach out to us.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Conclusion</h1>
            <p className=" mt-5">
              Thank you for exploring Aerospace Laboratory. We invite you to
              learn more about our research and join us in our mission to
              pioneer the future of aerospace technology and exploration.
            </p>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default AerospaceLab;
