import React, { useState, useRef, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Logo from "../../assets/images/logo.png";
import DropdownMenu from "../molecules/Dropdown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowCircleLeft,
  faArrowAltCircleRight,
  faClose,
} from "@fortawesome/free-solid-svg-icons";

const Nav = styled.div`
  .header {
  }

  .mobile-menu ul li {
    padding: 10px 20px;
  }

  .mobile-menu ul li:hover {
    background: #3a3333;
  }

  .menu-desktop ul li {
    padding: 0 20px;
    color: #f4f4f4;
  }
`;
const Header = () => {
  const [isMobileMenuOpen, setIsOpen] = useState(false);
  const [isDropdownMenuOpen, setIsDropdownOpen] = useState(false);
  const containerRef = useRef(null);
  const [hasHorizontalScroll, setHasHorizontalScroll] = useState(false);

  const handleScroll = (scrollOffset) => {
    const container = containerRef.current;
    if (container) {
      container.scrollLeft += scrollOffset;
    }
  };

  useEffect(() => {
    if (containerRef.current) {
      const hasScroll =
        containerRef.current.scrollWidth > containerRef.current.clientWidth;
      setHasHorizontalScroll(hasScroll);
    }
  }, [containerRef]);

  const toggleMobileMenu = () => {
    setIsOpen(!isMobileMenuOpen);
  };

  const toggleDropDownMenu = () => {
    setIsDropdownOpen(!isDropdownMenuOpen);
  };

  const menuItems = [
    { text: "Home", to: "/" },
    { text: "Who we are", to: "/about" },
    { text: "What we do", to: "/services" },
  ];

  return (
    <Nav>
      <div
        className=" header w-full fixed bg-transparent z-30"
        onClick={() => (isDropdownMenuOpen ? toggleDropDownMenu() : "")}
      >
        <div className="relative flex items-center justify-between lg:px-10 p-2">
          <div className="w-16 h-16">
            <Link to="/">
              <img alt="SSSRI logo" src={Logo} loading="lazy" />
            </Link>
          </div>

          <div className="hidden md:flex menu-desktop">
            <div className=" font-extralight text-xl">
              <ul className="flex">
                {menuItems.map((item, index) => (
                  <li key={index}>
                    <Link to={item.to}>{item.text}</Link>
                  </li>
                ))}
                <li>
                  <DropdownMenu
                    isDropdownMenuOpen={isDropdownMenuOpen}
                    toggleDropDownMenu={toggleDropDownMenu}
                    containerRef={containerRef}
                    setHasHorizontalScroll={setHasHorizontalScroll}
                  />
                </li>
                <li>
                  <Link to="/nap">NAP</Link>
                </li>
                <li>
                  <Link to="/stem">STEM</Link>
                </li>
                <li>
                  <Link to="/contact">Contact Us</Link>
                </li>
              </ul>
            </div>
          </div>

          <div className="md:hidden flex items-center">
            <button
              onClick={toggleMobileMenu}
              className="text-white p-2 focus:outline-none"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`${isMobileMenuOpen ? "hidden" : "block"} h-8 w-8`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                className={`${isMobileMenuOpen ? "block" : "hidden"} h-6 w-6`}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {isMobileMenuOpen && (
        <div
          className="md:hidden mobile-menu text-white fixed overflow-y-scroll h-[90vh] left-0 w-full bg-black z-50"
          onClick={toggleMobileMenu}
        >
          <div className="py-10  text-xl">
            <ul className=" w-full">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">Who we are</Link>
              </li>
              <li>
                <Link to="/services">What we do</Link>
              </li>
              <li>
                <Link to="/aerospace">Aerospace Laboratory</Link>
              </li>
              <li>
                <Link to="/enviromental">Enviromental Laboratory</Link>
              </li>
              <li>
                <Link to="/robotics">Robotics Laboratory</Link>
              </li>
              <li>
                <Link to="/satellite">Satellite Laboratory</Link>
              </li>
              <li>
                <Link to="/nap">NAP</Link>
              </li>
              <li>
                <Link to="/stem">STEM Education</Link>
              </li>
              <li>
                <Link to="/contact">Contact us</Link>
              </li>
            </ul>
          </div>
          <div className=" text-white top-4 absolute right-4">
            <FontAwesomeIcon
              color="#ffffff"
              icon={faClose}
              className="text-4xl"
            />
          </div>
        </div>
      )}

      {isDropdownMenuOpen && hasHorizontalScroll && (
        <div className="absolute w-full top-1/2 flex justify-between z-40">
          <button onClick={() => handleScroll(-100)}>
            <FontAwesomeIcon
              icon={faArrowCircleLeft}
              className=" text-6xl opacity-10 hover:opacity-100"
            />
          </button>

          <button onClick={() => handleScroll(100)}>
            <FontAwesomeIcon
              icon={faArrowAltCircleRight}
              className=" text-6xl opacity-10 hover:opacity-100"
            />
          </button>
        </div>
      )}
    </Nav>
  );
};

export default Header;
