import React from "react";
import Slider from "react-slick";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { LazyLoadImage } from "react-lazy-load-image-component";

import KTU from "../../assets/images/clients/ktulogo.png";
import TTU from "../../assets/images/clients/ttulogo.png";
import UENR from "../../assets/images/clients/un_natural_resources.webp";
import UDS from "../../assets/images/clients/udslogo.png";
import FABRIMETAL from "../../assets/images/clients/FabimetalLOGO-061.png";
import CPP from "../../assets/images/clients/cenpower.png";
import AEF from "../../assets/images/clients/adonai.png";
import SAPL from "../../assets/images/clients/sunon.png";
import FERO from "../../assets/images/clients/FFL-Logo-min-3.png";
import GLCL from "../../assets/images/clients/GMC-LOGO-1.avif";

//"../images/partners/need"

// Create a Logo component that displays an image of a logo inside a styled div
const Logo = ({ src, bgColor, alt }) => {
  // Create a styled div that wraps the image
  const LogoWrapper = styled.div`
    height: 100px;
    margin: 10px;
    padding: 10px;
    background: ${bgColor};
    display: flex;
    align-items: center;
    justify-content: center;
  `;

  // Return the Logo component
  return (
    <LogoWrapper>
      <LazyLoadImage src={src} alt={alt} />
    </LogoWrapper>
  );
};

const SummaryEsteemedClients = () => {
  const Logos = [
    { name: KTU, url: "", bg: "#cccccc" },
    { name: TTU, url: "", bg: "red" },
    { name: UENR, url: "", bg: "#13a3eb" },
    { name: UDS, url: "", bg: "#3a3333" },
    { name: FABRIMETAL, url: "", bg: "#00ffd9" },
    { name: CPP, url: "", bg: "#b600d7" },
    { name: AEF, url: "", bg: "white" },
    { name: SAPL, url: "", bg: "#e1ff00" },
    { name: FERO, url: "", bg: "#a99393" },
    { name: GLCL, url: "", bg: "#232121" },
  ];
  // Define some settings for the Slider component
  const settings = {
    className: "center",
    centerMode: true,
    autoplay: true,
    speed: 500,
    infinite: true,
    arrows: false,
    dots: false,
    slidesToShow: 3,
    slidesToScroll: 1,
    rows: 2,
    slidesPerRow: 1,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  // Return the LogoCarousel component
  return (
    <div className="  lg:p-20 p-0">
      <Slider {...settings}>
        {Logos.map((src) => (
          <Logo src={src.name} key={src} bgColor={src.bg} alt={src.alt} />
        ))}
      </Slider>
    </div>
  );
};

export default SummaryEsteemedClients;
