import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLeaf,
  faRobot,
  faSatellite,
  faRocket,
} from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";

const Dropdown = styled.div`
  .menu-item {
    background: #dcdbdb;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }
  .menu-item:hover {
    box-shadow: 0 10px 14px 0 black;
  }

  /* Width and Height of the scrollbar (horizontal and vertical) */
  ::-webkit-scrollbar {
    height: 0px; /* Height of the horizontal scrollbar */
  }

  ::-webkit-scrollbar-track {
    border-radius: 0px;
  }

  /* Handle (vertical and horizontal) */
  ::-webkit-scrollbar-thumb {
    background: transparent ! importance;
  }
`;

function DropdownMenu({
  isDropdownMenuOpen,
  toggleDropDownMenu,
  containerRef,
  setHasHorizontalScroll,
}) {
  return (
    <Dropdown>
      <div className=" relative group">
        <button
          onClick={() => {
            toggleDropDownMenu();
            setHasHorizontalScroll(true);
          }}
        >
          Laboratories
        </button>
        {isDropdownMenuOpen && (
          <div className="fixed mt-2 text-black space-y-2 w-full left-0 bg-white  h-[55vh] top-[70px]">
            <div className=" ">
              <div className=" flex justify-center">
                <div
                  className=" overflow-x-auto flex menu-scroll"
                  ref={containerRef}
                >
                  <Link to="/enviromental" className="p-10">
                    <div className="w-64 menu-item p-10">
                      <div className="">
                        <span>
                          <FontAwesomeIcon
                            icon={faLeaf}
                            className=" text-6xl"
                          />
                        </span>
                        <h3 className=" text-2xl pb-3">Environmental</h3>
                        <p className=" text-sm font-extralight">
                          A leading environmental research Laboratory dedicated
                          to advancing the field of environmental science and
                          research.
                        </p>
                      </div>
                    </div>
                  </Link>

                  <Link to="/aerospace" className="p-10">
                    <div className=" w-64 menu-item  p-10 ">
                      <div>
                        <span>
                          <FontAwesomeIcon
                            icon={faRocket}
                            className=" text-6xl"
                          />
                        </span>
                        <h3 className=" text-2xl pb-3">Aerospace</h3>
                        <p className=" text-sm font-extralight">
                          Welcome to our Aerospace Laboratory - a leading
                          aerospace research laboratory dedicated to advancing
                          the frontiers...
                        </p>
                      </div>
                    </div>
                  </Link>

                  <Link to="/robotics" className="p-10">
                    <div className=" w-64 menu-item  p-10">
                      <div>
                        <span>
                          <FontAwesomeIcon
                            icon={faRobot}
                            className=" text-6xl"
                          />
                        </span>
                        <h3 className=" text-2xl pb-3">Robotics</h3>
                        <p className=" text-sm font-extralight">
                          The Robotics Laboratory is a leading robotics research
                          Laboratory committed to advancing the field of
                          robotics....
                        </p>
                      </div>
                    </div>
                  </Link>

                  <Link to="/satellite" className="p-10">
                    <div className=" w-64 menu-item  p-10">
                      <div>
                        <span>
                          <FontAwesomeIcon
                            icon={faSatellite}
                            className=" text-6xl"
                          />
                        </span>
                        <h3 className=" text-2xl pb-3">Satellite</h3>
                        <p className=" text-sm font-extralight pb-5">
                          Satellite Systems Research and Exploration Laboratory,
                          a cutting-edge research and exploration...
                        </p>
                      </div>
                    </div>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Dropdown>
  );
}

export default DropdownMenu;
