import styled from "styled-components";
import Button from "../atoms/Button";
const Summary = styled.div`
  .text-secondary {
    color: #d9d5d5;
  }
`;

const SummaryAboutUs = () => {
  return (
    <Summary className=" bg-black flex justify-center items-center">
      <div className=" text-secondary lg:w-1/2 w-full p-10 lg:text-xl md:text-3xl text-md md:text-left ">
        Space Science Systems Research Institute (SSSRI) is at the forefront of
        advancing space science and technology in Ghana and across Africa. As an
        institution committed to excellence, our mission is to shape and elevate
        Ghana's space capabilities while ensuring that the investments in space
        yield meaningful benefits for our citizens and the continent at large.
        <div className="flex justify-center my-10 uppercase lg:text-lg md:text-2xl text-sm">
          <Button text="Our Services" link="/services" textColor="white" />
        </div>
      </div>
    </Summary>
  );
};

export default SummaryAboutUs;
