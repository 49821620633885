import React, { Component } from "react";
import VisibilitySensor from "react-visibility-sensor";

class VisibilitySensorText extends Component {
  state = {
    visibility: false,
  };

  render() {
    return (
      <VisibilitySensor
        onChange={(isVisible) => {
          this.setState({ visibility: isVisible });
        }}
      >
        <p
          style={{
            opacity: this.state.visibility ? 1 : 0,
            transition: "opacity 1000ms linear",
          }}
        >
          {this.props.text}
        </p>
      </VisibilitySensor>
    );
  }
}

export default VisibilitySensorText;
