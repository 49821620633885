import AboutContent from "../components/organisms/AboutContent";
import AboutTitle from "../components/organisms/AboutTitle";
import Helmet from "react-helmet";

const AboutUsPage = () => {
  return (
    <div>
      <Helmet>
        <title>SSSRI - About Us</title>
      </Helmet>
      <div className="fixed w-full h-[76px] bg-blue-500 z-20"></div>
      <AboutTitle />
      <AboutContent />
    </div>
  );
};

export default AboutUsPage;
