import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BgImage from "../assets/images/backgrounds/satellitelab.webp";
import { Helmet } from "react-helmet";

const Container = styled.div`
  .header {
    background: url(${BgImage});
    background-attachment: fixed;
    background-size: cover;
    background-position: center;
  }
  .introduction {
  }
`;
const SatelliteLab = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the device is mobile
    const checkIsMobile = () => {
      // Use matchMedia to check if the screen width is less than a certain value
      const mobileMediaQuery = window.matchMedia("(max-width: 768px)");
      setIsMobile(mobileMediaQuery.matches);
    };

    // Initial check
    checkIsMobile();

    // Add event listener for changes in screen width
    window.addEventListener("resize", checkIsMobile);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  return (
    <Container className=" p-0 m-0">
      <Helmet>
        <title>SSSRI - Satellite Laboratory</title>
      </Helmet>
      <div className="fixed w-full h-[76px] bg-blue-500 z-20"></div>
      {isMobile ? (
        <div className="flex justify-center items-center uppercase lg:text-3xl ">
          <div className=" mt-[76px] h-[50vh] relative">
            <div className=" absolute w-full h-full flex items-center justify-center z-10">
              <div className="text-center">
                <h2 className="font-extrabold uppercase lg:text-7xl md:text-7xl text-4xl text-primary">
                  Satellite systems
                </h2>
                <h2 className="font-extrabold uppercase lg:text-7xl md:text-7xl text-4xl text-primary">
                  Research and Exploration
                </h2>
                <p className=" lg:text-9xl md:text-6xl text-2xl text-primary">
                  laboratory
                </p>
                <div className=" lg:text-sm md:text-3xl mt-5 px-10 font-bold text-white mx-4">
                  Exploring the Final Frontier of Satellite Systems Research
                </div>
              </div>
            </div>
            <img src={BgImage} className="w-full h-full" alt="Nature" />
          </div>
        </div>
      ) : (
        <div className="header pt-40 flex justify-center items-center uppercase lg:h-[90vh] h-[90vh] lg:text-3xl">
          <div className="text-center">
            <h2 className="font-extrabold uppercase lg:text-7xl md:text-7xl text-4xl text-primary">
              Satellite systems
            </h2>
            <h2 className="font-extrabold uppercase lg:text-7xl md:text-7xl text-4xl text-primary">
              Research and Exploration
            </h2>

            <p className=" lg:text-9xl md:text-6xl text-xl text-primary">
              laboratory
            </p>
            <p className=" lg:text-sm md:text-3xl mt-10 px-10 text-white font-bold">
              Exploring the Final Frontier of Satellite Systems Research
            </p>
          </div>
        </div>
      )}
      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            Satellite systems Research and Exploration Laboratory, a
            cutting-edge research and exploration laboratory dedicated to
            advancing the field of satellite systems. We are committed to
            pushing the boundaries of knowledge and technology to enhance
            satellite design, propulsion, communication, and applications.
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Our Mission and Goals</h1>
            <p>
              At Satellite systems Research and Exploration Laboratory, our
              mission is clear: to drive innovation in satellite systems
              research. Our goals include:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li className="">Advancing satellite technology and design.</li>
                <li>Enhancing satellite propulsion systems.</li>
                <li>Innovating in satellite communication.</li>
                <li>Exploring new applications for satellite systems.</li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Research Areas</h1>
            <p>Our laboratory specializes in several key research areas:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li className="">
                  <span className=" font-bold">Satellite Design:</span> We work
                  on developing next-generation satellites, optimizing their
                  form and function.
                </li>
                <li>
                  <span className=" font-bold">Satellite Propulsion:</span>
                  Researching advanced propulsion systems to improve satellite
                  mobility.
                </li>
                <li>
                  <span className=" font-bold">Satellite Communication:</span>
                  Exploring novel communication methods to boost global
                  connectivity.
                </li>
                <li>
                  <span className=" font-bold">Remote Sensing:</span> Leveraging
                  satellites for Earth observation, environmental monitoring,
                  and disaster management.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Infrastructure and Resources
            </h1>
            <p>Our state-of-the-art facility boasts:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Cutting-edge testing facilities.</li>
                <li>Advanced satellite prototypes for experimentation.</li>
                <li>High-performance computing resources.</li>
                <li>
                  Specialized software tools for satellite design and analysis.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Collaborations</h1>
            <p className=" mt-5">
              Collaboration is at the heart of our work. We partner with leading
              research institutions, government agencies, and industry experts
              to drive innovation and tackle complex challenges.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Achievements</h1>
            <p>
              Over the years, SSSRI has achieved numerous milestones, including:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Technological breakthroughs.</li>
                <li>Publications in prestigious journals.</li>
                <li>Successful satellite missions with global impact.</li>
              </ul>
            </p>
          </p>
        </div>
      </div>
      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Team and Expertise</h1>

            <p className=" mt-5">
              Our multidisciplinary team of experts brings together diverse
              talents, skills, and knowledge to tackle complex challenges in
              satellite systems research.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Educational Initiatives
            </h1>
            <p>As part of our commitment to knowledge sharing, we offer:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Student internships for aspiring satellite researchers.</li>
                <li>Workshops and educational outreach programs.</li>
                <li>
                  Opportunities for the next generation of satellite scientists
                  and engineers.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Impact on Society</h1>
            <p>
              The work done at Satellite systems Research and Exploration
              Laboratory has far-reaching effects, including:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Enhancing global telecommunications.</li>
                <li>Improving weather forecasting.</li>
                <li>Facilitating environmental monitoring.</li>
                <li>
                  Contributing to disaster response and national security
                  efforts.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Future Directions</h1>
            <p>
              Our laboratory's future is filled with exciting prospects. We are
              poised to
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Launch new research projects.</li>
                <li>Collaborate on international missions.</li>
                <li>
                  Make significant contributions to the field of satellite
                  systems research and exploration.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Importance of Satellite Systems
            </h1>
            <p className=" mt-5">
              Satellite systems are a cornerstone of modern society, influencing
              how we connect, communicate, and understand the world around us.
              Their impact is immeasurable, and [Laboratory Name] is at the
              forefront of shaping this future.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Questions and Engagement
            </h1>
            <p className=" mt-5">
              We welcome your questions and engagement. If you have inquiries
              about our research, projects, or collaboration opportunities,
              please don't hesitate to reach out to us.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Conclusion</h1>
            <p className=" mt-5">
              Thank you for exploring [Laboratory Name]. We invite you to learn
              more about our research and join us in our mission to advance
              satellite systems for a brighter and more connected world.
            </p>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default SatelliteLab;
