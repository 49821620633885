import React, { useState, useEffect } from "react";
import styled from "styled-components";
import BgImage from "../assets/images/backgrounds/envresearch.jpg";
import Helmet from "react-helmet";

const Container = styled.div`
  .header {
    background: url(${BgImage});
    background-position: center;
    background-size: cover;
  }
  .introduction {
  }
`;
const EnvironmentalLab = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Function to check if the device is mobile
    const checkIsMobile = () => {
      // Use matchMedia to check if the screen width is less than a certain value
      const mobileMediaQuery = window.matchMedia("(max-width: 768px)");
      setIsMobile(mobileMediaQuery.matches);
    };

    // Initial check
    checkIsMobile();

    // Add event listener for changes in screen width
    window.addEventListener("resize", checkIsMobile);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  return (
    <Container className=" p-0 m-0">
      <Helmet>
        <title>SSSRI - Environmental Laboratory</title>
      </Helmet>
      <div className="fixed w-full h-[76px] bg-blue-500 z-20"></div>
      {isMobile ? (
        <div className="flex justify-center items-center uppercase lg:text-3xl ">
          <div className=" mt-[76px] h-[50vh] relative">
            <div className=" absolute w-full h-full flex items-center justify-center z-10">
              <div className="text-center">
                <h2 className="font-extrabold uppercase lg:text-7xl md:text-7xl text-4xl text-primary">
                  Environmental Research
                </h2>
                <p className=" lg:text-9xl md:text-6xl text-2xl text-primary">
                  laboratory
                </p>
                <div className=" lg:text-sm md:text-3xl mt-5 px-10 font-bold text-[#039b1a] bg-[#fcfcfcab] mx-4">
                  Leading the Way in Environmental Research for a More
                  Sustainable Future
                </div>
              </div>
            </div>
            <img src={BgImage} className="w-full h-full" alt="Nature" />
          </div>
        </div>
      ) : (
        <div className="header flex justify-center items-center uppercase h-screen lg:text-3xl">
          <div className="text-center">
            <h2 className="font-extrabold uppercase lg:text-7xl md:text-7xl text-4xl text-primary">
              Environmental Research
            </h2>
            <p className=" lg:text-9xl md:text-6xl text-xl text-primary">
              laboratory
            </p>
            <p className=" lg:text-lg md:text-3xl mt-5 px-10 font-bold text-[#039b1a]">
              Leading the Way in Environmental Research for a More Sustainable
              Future
            </p>
          </div>
        </div>
      )}

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            Welcome to the Environmental Research Laboratory, a cutting-edge
            research facility dedicated to advancing environmental science and
            contributing to the sustainable future of our planet. Our laboratory
            is committed to pushing the boundaries of knowledge and technology
            to address pressing environmental challenges and promote a
            healthier, more resilient Earth.
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Our Mission and Goals</h1>
            <p>
              At the Environmental Research Laboratory, our mission is clear: to
              drive innovation in environmental research. Our goals include:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li className="">
                  Advancing environmental monitoring and research.
                </li>
                <li>
                  Developing sustainable technologies for environmental
                  protection.
                </li>
                <li>
                  Innovating in data analysis and interpretation for informed
                  decision-making.
                </li>
                <li>
                  Exploring new applications for environmental research to
                  benefit society.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Research Areas</h1>
            <p>Our laboratory specializes in several key research areas:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li className="">
                  <span className=" font-bold">Environmental Monitoring:</span>{" "}
                  We focus on developing advanced monitoring techniques for air
                  quality, water quality, and biodiversity to understand and
                  mitigate environmental impacts.
                </li>
                <li>
                  <span className=" font-bold">Sustainable Technologies: </span>
                  Researching and developing eco-friendly technologies to
                  address environmental challenges, from renewable energy
                  solutions to waste management innovations.
                </li>
                <li>
                  <span className=" font-bold">
                    Data Analysis and Interpretation:
                  </span>
                  Exploring novel methods for analyzing and interpreting
                  environmental data to provide valuable insights for
                  policymakers and researchers.
                </li>
                <li>
                  <span className=" font-bold">Climate Change: </span>{" "}
                  Investigating the impacts of climate change and developing
                  strategies for adaptation and mitigation.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Infrastructure and Resources
            </h1>
            <p>Our state-of-the-art facility boasts:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Cutting-edge environmental monitoring stations.</li>
                <li>
                  Advanced research equipment for data collection and analysis.
                </li>
                <li>
                  High-performance computing resources for complex modeling and
                  simulations.
                </li>
                <li>
                  Specialized software tools for environmental data analysis.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Collaborations</h1>
            <p className=" mt-5">
              Collaboration is at the heart of our work. We partner with leading
              research institutions, environmental organizations, and industry
              experts to drive innovation and address complex environmental
              challenges.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Achievements</h1>
            <p>
              Over the years, SSSRI has achieved numerous milestones, including:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Technological breakthroughs in environmental monitoring..
                </li>
                <li>Publications in prestigious environmental journals.</li>
                <li>
                  Successful projects with a positive impact on local and global
                  ecosystems.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>
      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Team and Expertise</h1>

            <p className=" mt-5">
              Our multidisciplinary team of experts brings together diverse
              talents, skills, and knowledge to tackle complex challenges in
              environmental research.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Educational Initiatives
            </h1>
            <p>As part of our commitment to knowledge sharing, we offer:</p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Student internships for aspiring environmental researchers.
                </li>
                <li>
                  Workshops and educational outreach programs to promote
                  environmental awareness.
                </li>
                <li>
                  Opportunities for the next generation of environmental
                  scientists and engineers.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Impact on Society</h1>
            <p>
              The work done at the Environmental Research Laboratory has
              far-reaching effects, including:
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>Enhancing environmental sustainability.</li>
                <li>
                  Contributing to the understanding of ecosystems and
                  biodiversity.
                </li>
                <li>
                  Providing valuable data for informed environmental policies.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Future Directions</h1>
            <p>
              Our laboratory's future is filled with exciting prospects. We are
              poised to
            </p>
            <p className=" mt-5">
              <ul className=" list-disc pl-14">
                <li>
                  Launch new research projects addressing emerging environmental
                  challenges.
                </li>
                <li>
                  Collaborate on international initiatives for global
                  environmental conservation.
                </li>
                <li>
                  Make significant contributions to the field of environmental
                  research and exploration.
                </li>
              </ul>
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Importance of Satellite Systems
            </h1>
            <p className=" mt-5">
              Environmental research is crucial for understanding and addressing
              the challenges facing our planet. Our laboratory is at the
              forefront of shaping a sustainable future for generations to come.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">
              Questions and Engagement
            </h1>
            <p className=" mt-5">
              We welcome your questions and engagement. If you have inquiries
              about our research, projects, or collaboration opportunities,
              please don't hesitate to reach out to us.
            </p>
          </p>
        </div>
      </div>

      <div className="introduction flex justify-center pt-20">
        <div className=" lg:w-1/2 md:w-4/5 w-full px-10">
          <p className=" lg:text-xl md:text-3xl text-md">
            <h1 className=" font-bold text-3xl pb-5">Conclusion</h1>
            <p className=" mt-5">
              Thank you for exploring the Environmental Research Laboratory. We
              invite you to learn more about our research and join us in our
              mission to advance environmental science for a healthier and more
              sustainable world.
            </p>
          </p>
        </div>
      </div>
    </Container>
  );
};

export default EnvironmentalLab;
