import styled from "styled-components";
import HelpDesk from "../assets/images/backgrounds/helpdesk.webp";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhone,
  faMapMarker,
  faEnvelope,
  faMobilePhone,
} from "@fortawesome/free-solid-svg-icons";
import ContactForm from "../components/molecules/ContactForm";
import Helmet from "react-helmet";

const Contact = styled.div`
  .header {
    background-image: url(${HelpDesk});
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .contact-calie {
    background: #ffffff8f;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .title {
    font-size: 30px;
  }
`;
const ContactPage = () => {
  return (
    <Contact>
      <Helmet>
        <title>SSSRI - Contact us</title>
      </Helmet>

      <div className="fixed w-full h-[76px] bg-blue-500 z-20"></div>
      <div className="header h-[50vh] pt-[10px]"></div>

      <div className="grid lg:grid-cols-2">
        <div className="relative lg:p-0 px-10">
          <div className="-mt-28 lg:px-18 contact-calie lg:mx-40">
            <div className=" text-center pt-8 lg:text-4xl text-3xl font-bold text-primary uppercase text-white">
              Get in Touch with Us
            </div>
            <div className="w-full mt-10 left-0 text-center font-semibold text-lg">
              We are open from 8am - 5pm (Weekdays only)
            </div>
            <div className=" lg:p-16 py-10 px-4 flex justify-center">
              <div className="relative">
                <div className=" mb-16 ">
                  <div className="flex">
                    <div className="p-4 mr-4 flex items-center rounded">
                      <FontAwesomeIcon
                        icon={faMapMarker}
                        style={{ color: "red", fontSize: "34px" }}
                        color="red"
                      />
                    </div>
                    <div className="flex-col">
                      <p className="mb-1 font-light text-lg">Address</p>
                      <p className="font-bold text-xl text-primary">
                        Akosombo road, Ashaiman round about near Bank of Africa.
                      </p>
                      <p className="font-bold mt-2 text-xl text-primary">
                        P.O.BOX CS 7579{" "}
                        <span className=" lg:inline hidden">-</span> Tema, Ghana
                      </p>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex">
                    <div className="p-4 mr-4 flex items-center rounded">
                      <FontAwesomeIcon
                        icon={faEnvelope}
                        style={{ color: "red", fontSize: "34px" }}
                      />
                    </div>
                    <div className="flex-col">
                      <p className="mb-1 font-light text-lg">Email</p>
                      <p className="font-bold text-xl text-primary">
                        info@sssri.edu.gh
                      </p>
                    </div>
                  </div>
                </div>
                <div className=" mt-16 ">
                  <div className="flex">
                    <div className="p-4 mr-4 flex items-center rounded">
                      <FontAwesomeIcon
                        icon={faMobilePhone}
                        style={{ color: "#FFF", fontSize: "34px" }}
                      />
                    </div>
                    <div className="flex-col">
                      <p className="mb-1 font-light text-lg">Mobile</p>
                      <div className="font-bold text-xl text-primary">
                        <p>
                          <a href="tel:0205940250">(+233)205940250</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>

                <div className=" mt-16 ">
                  <div className="flex">
                    <div className="p-4 mr-4 flex items-center rounded">
                      <FontAwesomeIcon
                        icon={faPhone}
                        style={{ color: "#FFF", fontSize: "34px" }}
                      />
                    </div>
                    <div className="flex-col">
                      <p className="mb-1 font-light text-lg">Phone</p>
                      <div className="font-bold text-xl text-primary">
                        <p>
                          <a href="tel:0246702629">+233-(0)30-3960746</a>
                        </p>
                        <p>
                          <a href="tel:0246702629">+233-(0)30-3309821</a>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-20 lg:p-20">
          <div className="contact-calie lg:p-20 p-10">
            <h1 className="text-center lg:text-3xl text-primary uppercase text-xl mb-10">
              Request a Call Back
            </h1>
            <ContactForm />
          </div>
        </div>
      </div>
    </Contact>
  );
};

export default ContactPage;
