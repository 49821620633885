import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSatelliteDish,
  faRocket,
  faSmog,
  faRobot,
  faDatabase,
  faTemperatureHigh,
  faLaptopCode,
} from "@fortawesome/free-solid-svg-icons";
import Button from "../atoms/Button";

const CardLayout = styled.div`
  .flip-card {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    transition: 0.3s;
    perspective: 1000px;
    cursor: pointer;
  }
  .flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
  }

  .flip-card-front,
  .flip-card-back {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  }

  .flip-card-front {
    color: black;
  }

  .flip-card-back {
    background-color: #80003b;
    color: white;
    transform: rotateY(180deg);
  }

  .flip-card-back ul li {
    padding: 5px;
    font-size: 20px;
  }

  .flip-card-back .item-style {
    display: block;
    margin-top: -30px;
    margin-left: 30px;
  }

  .flip-card-back ul li:before {
    content: "✓";
    color: green;
    font-weight: bolder;
    margin-right: 15px;
  }

  .frontside .text-icon {
    font-size: 50px;
  }

  .backside .text-title,
  .frontside .text-title {
    font-size: 30px;
    line-height: 1;
  }

  .frontside .description {
    text-align: left;
  }
`;

const Card = ({ content }) => {
  const renderFront = ({ icon, title, description }) => {
    return (
      <div className="frontside">
        <div className="flex h-28">
          <div className="text-icon">
            <FontAwesomeIcon icon={icon} />
          </div>
          <div className="text-title flex ml-10 flex-grow items-center">
            {title}
          </div>
        </div>
        <div className="description pt-4">{description}</div>
      </div>
    );
  };

  const renderBack = ({ icon, title, description }) => {
    return (
      <div className="backside px-6">
        <div>
          <div>{icon}</div>
          <div className="text-title text-center uppercase mb-6">{title}</div>
          <div>
            <ul>
              {description.map((item) => (
                <li>
                  <span className=" item-style">{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <CardLayout>
      <div className="flip-card">
        <div className="flip-card-inner">
          <div className="flip-card-front p-10">
            {renderFront(content.front)}
            <div className="flex justify-center my-4">
              <Button text="Read more" />
            </div>
          </div>
          <div className="flip-card-back absolute h-full top-0 w-full pt-10">
            {renderBack(content.back)}
          </div>
        </div>
      </div>
    </CardLayout>
  );
};

const cardContent = [
  {
    front: {
      icon: faRocket,
      title: "Space Science Systems Research",
      description:
        "Our Space Science Systems Research service encompasses the design, development, and implementation of cutting-edge space systems. We specialize in creating spacecraft, satellites, and payloads for space missions. Our team of experts ensures that every aspect of space science is meticulously researched and executed to advance our understanding of the cosmos.",
    },
    back: {
      title: "Unlimited specialties",
      description: [
        "Research and Development of Space Systems",
        "Spacecraft Design and Engineering",
        "Satellite Systems Integration",
        "Space Mission Planning and Execution",
        "Payload Development for Space Science Experiments",
      ],
    },
  },
  {
    front: {
      icon: faSmog,
      title: "Air Quality (Pollutant) Monitoring",
      description:
        "SSSRI Air Quality Monitoring service utilizes advanced stations for real-time data collection, swiftly identifying pollution sources. This proactive approach supports environmental management and public health protection. Cutting-edge technology provides essential insights for well-informed decision-making, fostering a healthier and more sustainable environment.",
    },
    back: {
      title: "Unlimited specialties",
      description: [
        "Air Quality Data Collection and Analysis",
        "Deployment of Air Quality Monitoring Stations",
        "Environmental Impact Assessments",
        "Real-time Air Quality Reporting",
        "Pollution Source Identification and Analysis",
      ],
    },
  },
  {
    front: {
      icon: faTemperatureHigh,
      title: "Climate Data and Satellite Imagery",
      description:
        "We offer comprehensive access to climate data and satellite imagery, aiding researchers, businesses, and decision-makers. Our services include climate data retrieval and analysis, access to extensive satellite image archives, climate modeling, remote sensing, and GIS mapping, empowering you to make informed decisions regarding climate-related matters.",
    },
    back: {
      title: "Unlimited specialties",
      description: [
        "Climate Data Retrieval and Analysis",
        "Access to Satellite Imagery Archives",
        "Climate Modeling and Simulation",
        "Remote Sensing Services",
        "Geographic Information Systems Mapping with Satellite Imagery",
      ],
    },
  },
  {
    front: {
      icon: faRobot,
      title: "Robotics",
      description:
        "In the field of Robotics, we excel in design, development, and automation solutions. Our robots are tailored to your specific requirements, whether for industrial automation, autonomous operations, or streamlining workflows through Robotic Process Automation (RPA). Our robot repair and maintenance services ensure consistent performance.",
    },
    back: {
      title: "Unlimited specialties",
      description: [
        "Robotics Design and Development",
        "Robotic Automation Solutions",
        "Autonomous Robot Programming",
        "Robotic Process Automation (RPA)",
        "Robot Repair and Maintenance Services",
      ],
    },
  },
  {
    front: {
      icon: faDatabase,
      title: "Data Center Installation & Maintenance",
      description:
        "The installation and upkeep of data centers are of paramount importance to enterprises dependent on data storage and processing. Our comprehensive services encompass data center design and construction, network and hardware setup, data backup and recovery solutions, and round-the-clock monitoring and maintenance, ensuring the uninterrupted operation of your data center.",
    },
    back: {
      title: "Unlimited specialties",
      description: [
        "Data Center Design and Build",
        "Data Center Networking and Hardware Installation",
        "Data Backup and Recovery Solutions",
        "24/7 Data Center Monitoring and Maintenance",
        "Data Center Optimization and Efficiency Services",
      ],
    },
  },
  {
    front: {
      icon: faSatelliteDish,
      title: "Satellite Installation",
      description:
        "Satellite Installation is vital for reliable satellite communication and entertainment services. We specialize in satellite dish installation and alignment, satellite communication systems setup, satellite internet provision, satellite TV installation, and equipment maintenance and repair to ensure uninterrupted connectivity and entertainment.",
    },
    back: {
      title: "Unlimited specialties",
      description: [
        "Satellite Dish Installation and Maintenance",
        "Satellite Communication Systems Setup",
        "Satellite Imagery and Data Services",
        "Satellite Internet Services",
        "Remote Sensing and GIS Applications",
      ],
    },
  },
];

const LastItem = [
  {
    front: {
      icon: faLaptopCode,
      title: "STEM Education",
      description:
        "STEM Education is at the heart of inspiring the innovators of tomorrow. We specialize in developing engaging STEM curricula, organizing workshops, competitions, and outreach programs that encourage a love for science and technology. Our STEM teacher training programs equip educators to effectively teach STEM subjects.",
    },
    back: {
      title: "Unlimited specialties",
      description: [
        "STEM Curriculum Development",
        "Robotics and Space Science Workshops",
        "Science and Technology Competitions",
        "Educational Outreach Programs",
        "STEM Teacher Training",
      ],
    },
  },
];

const Services = styled.div`
  .grid-container {
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: 1fr;
    grid-column-gap: 30px;
    grid-row-gap: 30px;
  }
`;
const ServiceContent = () => {
  return (
    <Services>
      <div className=" grid lg:grid-cols-3">
        {cardContent.map((item) => (
          <div className="relative">
            <Card content={item} />
          </div>
        ))}
      </div>
      <div className="flex justify-center">
        <div className="">
          {LastItem.map((item) => (
            <div className="relative">
              <Card content={item} />
            </div>
          ))}
        </div>
      </div>
    </Services>
  );
};

export default ServiceContent;
