import styled from "styled-components";
import { Link } from "react-router-dom";
import { Youtube, Facebook, X } from "react-feather";

const Container = styled.div`
  background-color: #0081ff;
  color: #eeeeee;
`;
const Footer = () => {
  function getCurrentYear() {
    return new Date().getFullYear();
  }
  return (
    <Container className=" pt-20 mt-20">
      <div className="lg:grid lg:grid-cols-3">
        <div className=" mb-20">
          <div className="relative">
            <div className="px-10">
              <h3 className=" text-left mb-4">About SSSRI</h3>
              <div className="inline">
                A pioneering private institute in Ghana and Africa, specializing
                in space science and technology. We excel in turning visionary
                ideas into reality and are dedicated to delivering excellence in
                all their endeavors.&nbsp;
                <Link to="/about" className=" text-black">
                  Read more...
                </Link>{" "}
              </div>
            </div>
          </div>
        </div>
        <div className="flex justify-center mb-20">
          <div className=" grid grid-cols-2 mx-8">
            <div>
              <ul>
                <li className="pb-1 hover:text-black ">
                  <Link to="/">Home</Link>
                </li>
                <li className="py-1 hover:text-black">
                  <Link to="/about">About us</Link>
                </li>
                <li className="py-1 hover:text-black ">
                  <Link to="/services">Services</Link>
                </li>
                <li className="py-1 hover:text-black ">
                  <Link to="/projects">projects</Link>
                </li>
                <li className="py-1 hover:text-black ">
                  <Link to="/research">Researches</Link>
                </li>
                <li className="py-1 hover:text-black ">
                  <Link to="/research">News & Events</Link>
                </li>
              </ul>
            </div>
            <div>
              <ul>
                <li className="py-1 hover:text-black ">
                  <Link to="/aerospace">Aerospace Laboratory</Link>
                </li>
                <li className="py-1 hover:text-black ">
                  <Link to="/enviromental">Enviromental Laboratory</Link>
                </li>
                <li className="py-1 hover:text-black ">
                  <Link to="/robotics">Robotics Laboratory</Link>
                </li>
                <li className="py-1 hover:text-black ">
                  <Link to="/satellite">Satellite Laboratory</Link>
                </li>
                <li className="py-1 hover:text-black ">
                  <Link to="/stem">STEM</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex justify-center">
          <div>
            <h3 className="mb-4">Follow SSSRI</h3>
            <div>
              <a href="https://www.facebook.com/SSSRIGH?fref=ts">
                <Facebook
                  style={{
                    fill: "white",
                    backgroundColor: "#212130",
                    borderRadius: "50%",
                    margin: "5px",
                    padding: "10px",
                    width: "48px",
                    height: "48px",
                  }}
                />
              </a>
              <a href="https://twitter.com/sssrighana">
                <X
                  style={{
                    fill: "white",
                    backgroundColor: "#212130",
                    borderRadius: "50%",
                    padding: "5px",
                    width: "48px",
                    height: "48px",
                    margin: "10px",
                  }}
                />
              </a>

              <a href="https://www.youtube.com/@sssrighana4665">
                <Youtube
                  style={{
                    fill: "white",
                    backgroundColor: "#212130",
                    borderRadius: "50%",
                    margin: "5px",
                    padding: "10px",
                    width: "48px",
                    height: "48px",
                  }}
                />
              </a>
            </div>
          </div>
        </div>
      </div>
      <p className=" font-extrabold text-center pb-2 mt-10">
        SSSRI &copy; {getCurrentYear()}
      </p>
    </Container>
  );
};

export default Footer;
