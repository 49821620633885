import styled from "styled-components";

const Container = styled.div`
  .container {
    background: ${(props) => props.bgColor || "transparent"};
    border: 2px solid #007bff;
    cursor: pointer;
    transition: transform 0.3s ease, border-color 0.3s ease;
    color: ${(props) => props.textColor || "black"};
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .container:hover {
    border-color: #0056b3;
    transform: scale(1.2);
  }
`;

const Button = ({ text, link, bgColor, textColor }) => {
  return (
    <Container bgColor={bgColor} textColor={textColor}>
      <div className="container px-10 py-3">
        <a href={link}>{text}</a>
      </div>
    </Container>
  );
};

export default Button;
