import styled from "styled-components";

const About = styled.div`
  .middle ul li {
    margin: 10px 0;
  }
`;
const AboutContent = () => {
  return (
    <About>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-2 xl:grid-cols-2 gap-4 p-10 text-black">
        <div class="bg-gray-200 p-4">
          <div className="py-10">
            <h1 className="mb-3 lg:text-3xl text-3xl font-bold">Mission</h1>
            <p>
              To shape the development of Ghana's space capabilities and ensure
              that investments in space deliver tangible benefits to the people
              of Ghana and Africa.
            </p>
          </div>
        </div>
        <div class="bg-gray-200 p-4">
          <div className="py-10">
            <h1 className="mb-3 lg:text-3xl text-3xl font-bold">Vision</h1>
            <p>
              An internationally-recognized Space Science Systems Institute
              undertaking inter-disciplinary research of high quality
              innovations, with the main intention of understanding space
              science for the common good of humanity.
            </p>
          </div>
        </div>
        <div class="bg-gray-200 p-4">
          <div className="py-10">
            <h1 className="mb-3 lg:text-3xl text-3xl font-bold">Aspirations</h1>
            <p>
              We aspire to be: Ghana's first private space science institute.
              Africa's leading space systems institute. A leader in space
              research and technology. Be a research centre for aspiring
              engineers to train A place where ideas become reality. An
              institute that delivers nothing but excellence.
            </p>
          </div>
        </div>
        <div class="bg-gray-200 p-4">
          <div className="py-10">
            <h1 className="mb-3 lg:text-3xl text-3xl font-bold">Aim</h1>
            <p>
              To be a world class research institute in Space Science Systems,
              deliver practical oriented results aimed at solving environmental
              problems faced the sub-region and play a leading role in Space
              Science systems development in Ghana, Africa and beyond
            </p>
          </div>
        </div>
        <div class="bg-gray-200 p-4">
          <div>
            <h1 className="mb-3 lg:text-3xl text-3xl font-bold">
              Partnerships and Alliances
            </h1>
            <p>
              We have established strategic partnerships with leading academic
              institutions and industrial entities both nationally and
              internationally. Our esteemed partners include Koforidua Technical
              University, the University of Energy and Natural Resources,
              Stem.org (USA), Airbus Defence and Space, and many more. These
              collaborations empower us to push the boundaries of space science
              and technology.
            </p>
          </div>
        </div>
        <div class="bg-gray-200 p-4">
          <div>
            <h1 className="mb-3 lg:text-3xl text-3xl font-bold">
              Media Coverage
            </h1>
            <p>
              SSSRI has garnered extensive media coverage for its groundbreaking
              research, innovative projects, and contributions to space science.
              Our work has been featured in numerous local and international
              media outlets, further solidifying our reputation as a leader in
              the field.
            </p>
          </div>
        </div>
      </div>
    </About>
  );
};

export default AboutContent;
