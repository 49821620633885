import styled from "styled-components";
import Button from "../atoms/Button";

const Hero = styled.div`
  background: black;

  .colored {
    color: #0081ff;
  }
`;

const SummaryConsultancyService = () => {
  return (
    <Hero className=" h-screen flex justify-center items-center overflow-x-hidden p-4">
      <div className="flex items-center justify-center  w-1/2">
        <div>
          <div className="uppercase text-center colored ">
            <p className=" uppercase tracking-normal font-bold lg:text-6xl md:text-8xl text-5xl text-primary text-center">
              Consulting
            </p>
            <p className=" uppercase tracking-wide font-bold lg:text-6xl md:text-8xl text-5xl text-primary text-center">
              for any Project
            </p>
          </div>

          <div className="text-white lg:text-xl md:text-3xl text-md p-6 text-center">
            Our versatile consulting services cater to a wide range of projects,
            offering expert guidance and insights tailored to your specific
            needs. Whether it's strategic planning, problem-solving, or
            enhancing project performance, our consulting team is dedicated to
            delivering customized solutions for your success.
          </div>

          <div className="flex justify-center my-10 uppercase lg:text-lg md:text-2xl text-sm">
            <Button
              text="Explore Our Expertise"
              link="/services"
              textColor="white"
            />
          </div>
        </div>
      </div>
    </Hero>
  );
};

export default SummaryConsultancyService;
