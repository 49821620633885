import styled from "styled-components";

import Carousel from "../molecules/Carousel";

const Container = styled.div`
  .button {
    font-size: 10px;
  }
  .small-text {
    font-size: 15px;
  }

  @media (max-width: 640px) {
    .small-text {
      font-size: 9px;
    }
  }
`;
const BottomBar = () => {
  return (
    <Container>
      <div className="absolute z-30 bottom-2 items-center w-full flex lg:justify-center justify-between bg-black text-white">
        <div className="font-bold pl-2 lg:text-5xl text-md py-1 w-3/4 ">
          <p>Empowering Ghana's Space Future</p>
          <p className=" small-text tracking-wider lg:py-4">
            @SPACE.SCIENCE.SYSTEMS.RESEARCH.INSTITUTE
          </p>
        </div>
        <div className=" rounded bg-blue-500 small-text lg:px-10 px-3 font-extrabold lg:py-4 py-2 mr-1 uppercase button lg:tracking-wider tracking-wider">
          <a href="/about">About us</a>
        </div>
      </div>
    </Container>
  );
};

const SummaryTitle = () => {
  return (
    <Container className="relative lg:h-screen">
      <BottomBar />
      <Carousel />
    </Container>
  );
};

export default SummaryTitle;
