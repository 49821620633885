import React from "react";
import { Helmet } from "react-helmet";

const NationAwareness = () => {
  return (
    <>
      <Helmet>
        <title>SSSRI - National Awareness Program</title>
      </Helmet>
      <div className="fixed w-full h-[76px] bg-blue-500 z-20"></div>
      <div className="pt-20">
        <div className="lg:flex justify-center lg:p-0 p-10">
          <div className="lg:w-1/2">
            <div className="py-5">
              <p className=" text-4xl">National Awareness Program (NAP)</p>
              <p className="py-4">
                The National Awareness Program is aimed at reaching out the
                idea, importance and the enormous benefits of space and
                satellite technology to attain National awareness and
                enlightenment through literal education and demonstrative
                activities on satellite technology. It is primarily aimed at
                educating the younger ones through Schools with the intent of
                arousing their interest in space and satellite technology. As a
                secondary function, the outreach office serves as an interface
                between international space and satellite organizations and
                SSSRI for inter research purposes.
              </p>
            </div>
            <div className="py-5">
              <p className=" text-4xl">Vision of National Awareness Program</p>
              <p className=" py-4">
                <ul className=" list-disc">
                  <li className=" px-4 py-1">
                    The National Awareness Program by the end 2016 is by target
                    aimed at reaching about 10% of senior high schools in Ghana
                    with much emphasis on schools in technology deprived areas
                  </li>
                  <li className=" px-4 py-1">
                    The program is expected to grow by 10% annually in reaching
                    out to Schools and communities.
                  </li>
                </ul>
              </p>
            </div>
            <div className="py-5">
              <p className=" text-4xl">
                The National Awareness Program will involve the following:
              </p>
              <p className=" py-4">
                <ul className=" list-decimal">
                  <li>
                    <span className=" text-2xl">
                      Schools Outreach Program (SOP)
                    </span>
                    <p className=" py-4">
                      It involves the going to various schools across the
                      country to educate them on Space Science technology and
                      its applications on their daily lives just to bring them
                      to appreciate what they are being thought in school and it
                      real application in space science technology. The program
                      involves the following:
                    </p>
                    <ul className=" list-disc">
                      <li className="px-4  py-1">Space Science Presentation</li>
                      <li className=" px-4 py-1">Demonstration</li>
                      <li className="px-4  py-1">
                        Discussions which also involve questions from students
                        based on the presentation and demonstration as well as
                        any other thing about Space Science.
                      </li>
                    </ul>
                  </li>
                  <li className=" py-4">
                    <span className=" text-2xl">
                      CanSat Training Program (CTP)
                    </span>
                    <p className=" py-4">
                      The CanSat Training Program is a training course that was
                      established for participants to experience the entire
                      cycle of CanSat development from the design to launch
                      either with high altitude weather balloons or of model
                      rockets. This includes learning the space technology and
                      teaching methods utilized in space engineering. The CanSat
                      educational project enables the integration and
                      collaboration among engineering and science careers, as
                      well as encouraging team work contains vast value.
                      Cansat’s are fully functional satellites designed with all
                      the common subsystems found on conventional satellites
                      e.g. power system, communications, data handling,
                      scientific payload, and the mechanical structure. In their
                      very basic form, these little satellites can be used to
                      measure temperature, air pressure, magnetic fields,
                      acceleration, radiation, air quality (CO2, dust), etc.
                      More advanced versions have onboard GPS receivers and
                      cameras. It is a small “satellite”, with all components,
                      such as sensors, actuators, and GPS, housed inside a
                      500-ml can. CanSat provides an affordable opportunity for
                      educators and students to acquire basic knowledge of space
                      engineering and to experience engineering challenges in
                      building a satellite. The CanSat project gave students
                      practical training in the development of an educational
                      satellite, students were able to conceptualize the
                      mission, plan and design as well as build and test their
                      products on the ground, charge and improve iteratively
                      their CanSat prototypes.
                    </p>
                  </li>
                  <li className=" py-4">
                    <span className=" text-2xl">
                      Community Outreach Programme (COP)
                    </span>
                    <p className=" py-4">
                      It will involve reaching out to the communities to educate
                      on preservation of the environment with respect to what
                      they can do to make the environment better in its green
                      state. This is achieved by using space science to
                      demonstrate the effects of human activities on the
                      environment and the harm it is causing it.
                    </p>
                  </li>
                  <li className=" py-4">
                    <span className=" text-2xl">
                      National Space Science Competitions (NSSC)
                    </span>
                    <p className=" py-4">
                      <span>
                        The following are the various forms it will take
                      </span>
                      <ul className=" list-disc">
                        <li className=" px-4 p-1">
                          Space Science Idea Contest (SSIC)
                        </li>
                        <li className=" px-4 p-1">Space Science Quizzes</li>
                        <li className=" px-4 p-1">
                          Debates on Space Science Topics
                        </li>
                        <li className=" px-4 py-1">CanSat Competition.</li>
                      </ul>
                    </p>
                  </li>
                </ul>
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default NationAwareness;
