import styled from "styled-components";
import BgImage from "../../assets/images/backgrounds/techsupport.webp";
import { useInView } from "react-intersection-observer";
import Button from "../atoms/Button";
import VisibilitySensorText from "../atoms/VisibilitySensorText";

const Container = styled.div`
  .bg-image {
    background-image: url(${BgImage});
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .bg-image::before {
    transform: translateY(100vh);
  }

  @media (max-width: 768px) {
    .bg-image::before {
      transform: none;
    }
  }
  @media (max-width: 1199px) {
    .description {
      background: #cecdcdb8;
    }
  }

  .fade-in {
    opacity: 0;
    transform: translateY(20px);
    transition: opacity 0.5s ease, transform 0.5s ease;
  }

  .fade-in.active {
    opacity: 1;
    transform: translateY(0);
  }
`;

const SummaryTechnicalService = () => {
  const { ref, inView } = useInView({
    triggerOnce: true, // Load only once when it enters the viewport
  });
  return (
    <Container>
      <div className="bg-image">
        <div
          ref={ref}
          className={`fade-in lg:h-screen py-20 flex lg:justify-end ${
            inView ? "active " : ""
          }`}
        >
          {inView ? (
            <div className="flex items-center justify-center lg:w-1/2 w-full ">
              <div>
                <div className="uppercase text-center text-primary ">
                  <p className=" tracking-tighter font-bold lg:text-6xl md:text-8xl text-5xl ">
                    <VisibilitySensorText text="Trusted Technical" />
                  </p>
                  <p className=" tracking-widest lg:text-5xl md:text-5xl text-4xl font-extralight">
                    <VisibilitySensorText text="Service Provider" />
                  </p>
                </div>

                <div className="text-black lg:text-xl md:text-3xl text-md m-4 lg:p-2 p-6 md:p-10 md:m-10 text-center bg-white">
                  We offers a comprehensive array of technical solutions
                  designed to meet your specific needs. With a focus on
                  reliability and expertise, we deliver prompt and efficient
                  technical services to ensure your operations run smoothly and
                  seamlessly. Count on us for all your technical service
                  requirements.
                </div>

                <div className="flex justify-center uppercase lg:text-lg md:text-2xl text-sm">
                  <Button
                    text="Explore Our Expertise"
                    link="/services"
                    textColor="white"
                    bgColor="transparent"
                  />
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
    </Container>
  );
};

export default SummaryTechnicalService;
