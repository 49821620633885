import React, { useState, useEffect, useRef } from "react";
import "../../styles/carouselStyle.css";
import Image1 from "../../assets/images/backgrounds/envresearch.jpg";
import Image2 from "../../assets/images/backgrounds/roboticshero.jpg";
import Image3 from "../../assets/images/backgrounds/rocketscrience.jpg";
import Image4 from "../../assets/images/backgrounds/satellitehero.jpg";

const Carousel = () => {
  const [slideIndex, setSlideIndex] = useState(1);
  const Images = [
    { title: "Pioneering Environmental Excellence", name: Image1 },
    { title: "Overhauled Robotic Expertise", name: Image2 },
    { title: "Astra Dynamics", name: Image3 },
    { title: "Africa's Orbital Influencers", name: Image4 },
  ];

  const imageRefs = useRef([]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSlideIndex((prevIndex) => (prevIndex % 4) + 1);
    }, 9000);

    return () => clearInterval(intervalId);
  }, []);

  const plusSlides = (n) => {
    setSlideIndex((prevIndex) => {
      let newIndex = prevIndex + n;

      if (newIndex > 4) {
        return 1;
      } else if (newIndex < 1) {
        return 4;
      }

      return newIndex;
    });
  };

  const showSlides = () => {
    let i;
    let slides = imageRefs.current;

    for (i = 0; i < slides.length; i++) {
      slides[i].style.display = "none";
    }

    slides[slideIndex - 1].style.display = "block";
  };

  useEffect(() => {
    showSlides();
  });

  return (
    <div className="slideshow-container lg:h-full bg-black">
      {Images.map((image, index) => (
        <div
          key={index}
          ref={(el) => (imageRefs.current[index] = el)}
          className="mySlides fade lg:h-full "
        >
          <div className=" h-full absolute flex items-center z-10 w-full bottom-0 ">
            <div className=" h-1/2 absolute w-full flex justify-start items-start p-4 ">
              {index === 0 ? (
                <div className="flex justify-center">
                  <p className="lg:text-7xl uppercase tracking-wide text-3xl font-extrabold text-white animated-text lg:w-1/2 w-full text-center">
                    {image.title}
                  </p>
                </div>
              ) : index === 1 ? (
                <div className=" flex justify-end">
                  <div className="lg:w-1/2 w-8/12 pl-4">
                    <p className="lg:text-7xl uppercase tracking-wide text-3xl font-extrabold typewriter-text text-white ">
                      {image.title}
                    </p>
                  </div>
                </div>
              ) : index === 2 ? (
                <div className="w-1/2 lg:ml-20 ml-4 ">
                  <p className="lg:text-7xl uppercase tracking-wide text-3xl font-extrabold text-white  scale-fade-text">
                    {image.title}
                  </p>
                </div>
              ) : (
                <div className="w-1/2 lg:ml-20 ml-4 ">
                  <p className="lg:text-7xl uppercase tracking-wide text-3xl font-extrabold text-white professional-text">
                    {image.title}
                  </p>
                </div>
              )}
            </div>
          </div>
          {index === 0 ? (
            <img
              src={image.name}
              className="w-full lg:h-full h-[80vh]"
              loading="lazy"
              alt="Nature"
            />
          ) : (
            <img
              src={image.name}
              className="w-full lg:h-full h-[80vh]"
              alt="Nature"
            />
          )}
        </div>
      ))}
      <button className="prev z-20" onClick={() => plusSlides(-1)}>
        ❮
      </button>

      <button className="next z-20" onClick={() => plusSlides(1)}>
        ❯
      </button>
    </div>
  );
};

export default Carousel;
