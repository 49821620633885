import styled from "styled-components";
import Button from "../atoms/Button";
import BgImage from "../../assets/images/backgrounds/training.webp";
import BgImage_sm from "../../assets/images/backgrounds/trainers.webp";

const Hero = styled.div`
  background-image: url(${BgImage_sm});
  background-position: center;
  background-size: cover;

  .bg-black-5000 {
    background-color: #000000ab;
  }
`;

const CircularImage = styled.div`
  width: 90%;
  height: 90%;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
`;
const SummaryTrainingService = () => {
  return (
    <Hero className=" lg:h-screen">
      <div className="unblured-background h-full w-full flex">
        <div className="flex items-center justify-center lg:w-1/2 w-full ">
          <div className="p-10">
            <div className="uppercase tracking-tighter font-bold lg:text-6xl md:text-8xl text-5xl text-primary text-center">
              Expert Training Services
            </div>

            <div className="bg-orange-600 p-2 text-white lg:text-xl md:text-3xl text-md my-4 lg:p-6 md:p-10 md:m-10 m-0 text-center">
              Unlock your full potential with our expert training services. We
              provide comprehensive training programs designed to help you excel
              in your chosen field. From skill development to advanced
              techniques, we've got you covered. Explore our offerings and take
              your expertise to the next level with our dedicated team of
              trainers.
            </div>

            <div className="flex justify-center my-10 uppercase lg:text-lg md:text-2xl text-sm">
              <Button
                text="Explore Our Expertise"
                link="/services"
                textColor="white"
              />
            </div>
          </div>
        </div>

        <div className=" hidden md:block  w-1/2">
          <div className="flex items-center h-full w-full justify-center">
            <CircularImage>
              <img src={BgImage} alt="" loading="lazy" />
            </CircularImage>
          </div>
        </div>
      </div>
    </Hero>
  );
};

export default SummaryTrainingService;
