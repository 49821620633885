import React from "react";
import SummaryTitle from "../components/organisms/SummaryTitle";
import SummaryAboutUs from "../components/organisms/SummaryAbout";
import SummaryTechnicalService from "../components/organisms/SummaryTechnicalService";
import SummaryTrainingService from "../components/organisms/SummaryTrainingService";
import SummaryConsultancyService from "../components/organisms/SummaryConsultancyService";
import SummaryValuedPartners from "../components/organisms/SummaryValuedPartners";
import SummaryEsteemedClients from "../components/organisms/SummaryEsteemedClients";
import { Helmet } from "react-helmet";

// Website summary page

const HomePage = () => {
  return (
    <>
      <Helmet>
        <title>
          Space Science Systems Research Institute | Ghana's Leading Space
          Research Center
        </title>
      </Helmet>
      <SummaryTitle />
      <SummaryAboutUs />
      <SummaryTechnicalService />

      <SummaryTrainingService />
      <SummaryConsultancyService />
      <div className=" pt-20">
        <h1 className=" font-extrabold uppercase lg:text-7xl md:text-7xl text-4xl text-primary text-center">
          Our trustors
        </h1>
        <div className="grid lg:grid-cols-2 grid-cols-1 ">
          <div className="pt-20">
            <div className="text-center text-2xl p-4 uppercase">
              Our Esteemed Clients
            </div>
            <SummaryValuedPartners />
          </div>
          <div className="pt-20">
            <div className="text-center text-2xl p-4 uppercase">
              Our Esteemed Clients
            </div>
            <SummaryEsteemedClients />
          </div>
        </div>
      </div>
    </>
  );
};

export default HomePage;
