import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Home from "./screens/HomePage";
import OurServicesPage from "./screens/OurServices";
import Header from "./components/molecules/Header";
import AboutUsPage from "./screens/AboutUs";
import ContactPage from "./screens/ContactPage";
import STEMEducation from "./screens/Stem";
import EnvironmentalLab from "./screens/EnvironmentalLab";
import AerospaceLab from "./screens/AerospaceLab";
import RoboticsLab from "./screens/RoboticsLab";
import SatelliteLab from "./screens/SatelliteLab";
import Footer from "./components/molecules/Footer";
import ScrollToTop from "./components/atoms/ScrollToTop";
import NationAwareness from "./screens/Nap";
import ReactGA from "react-ga";

// Initialize React GA with your Google Analytics tag ID
ReactGA.initialize("G-6MGX87JH8S");

// Create a function to track page views
const trackPageView = (location) => {
  // Get the current path
  const path = location.pathname + location.search + location.hash;
  // Send a page view event to Google Analytics
  ReactGA.pageview(path);
};

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <ScrollToTop />
        <Header />

        <ScrollToTop>
          <Routes>
            <Route path="/" component={trackPageView} />
            <Route exact index element={<Home />} />
            <Route path="/about" element={<AboutUsPage />} />
            <Route path="/services" element={<OurServicesPage />} />
            <Route path="/contact" element={<ContactPage />} />
            <Route path="/stem" element={<STEMEducation />} />
            <Route path="/nap" element={<NationAwareness />} />
            <Route path="/enviromental" element={<EnvironmentalLab />} />
            <Route path="/aerospace" element={<AerospaceLab />} />
            <Route path="/robotics" element={<RoboticsLab />} />
            <Route path="/satellite" element={<SatelliteLab />} />
          </Routes>
        </ScrollToTop>

        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
