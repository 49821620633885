import styled from "styled-components";
import President from "../../assets/images/backgrounds/team.webp";

const About = styled.div`
  img {
    width: 150%;
    height: 150%;
    object-fit: contain;
  }
  .caption {
    left: 250px;
  }

  .image {
    box-shadow: 0 6px 12px 0 rgba(0, 0, 0, 0.2);
  }
  .bg-color {
    background-color: #262e2f;
  }
`;
const AboutTitle = () => {
  return (
    <About>
      <div className="">
        <div className="grid lg:grid-cols-2 grid-cols-1 header ">
          <div className="relative lg:pt-12 pt-16">
            <div className=" absolute bottom-0 z-10 bg-white lg:w-1/2 lg:ml-4 mx-4 p-4 ">
              A research institute to look out for. We bring a personal and
              effective approach to every project we work on in space systems.{" "}
              <p className=" text-zinc-500"> - President, Manfred Quarshie</p>
            </div>
            <div className="bg-white">
              <img src={President} alt="" className=" " />
            </div>
          </div>
          <div className="flex items-center  text-black text-center">
            <div>
              <div className="mt-20 text-primary font-bold flex items-center justify-center lg:text-5xl text-4xl uppercase">
                SSSRI is a Team
              </div>
              <p className="lg:px-20 px-10 py-10">
                Space Science Systems Research Institute (SSSRI) is a pioneering
                institution at the forefront of space science and technology in
                Ghana and Africa. As a private space science institute, we are
                dedicated to advancing the frontiers of space research,
                technology, and innovation. Our state-of-the-art laboratories,
                collaborations with global partners, and a highly skilled team
                of professionals make us a trusted leader in the field. We pride
                ourselves on turning visionary ideas into reality, delivering
                nothing but excellence in all that we do.
              </p>
              <div className="">
                <h1 className="lg:text-3xl text-3xl font-bold">
                  Our Core Value
                </h1>
                <div className="w-full max-w-screen-md mx-auto lg:px-28 p-4">
                  <table classname="">
                    <tbody>
                      <tr className="">
                        <td class="font-bold lg:py-4 py-8">Integrity:</td>
                        <td class="text-left">
                          We conduct our research and operations with the utmost
                          ethical standards.
                        </td>
                      </tr>
                      <tr className="">
                        <td class="font-bold lg:py-4 py-8">Diversity:</td>
                        <td class="text-left">
                          We celebrate diversity and inclusivity, encouraging a
                          dynamic and inclusive environment.
                        </td>
                      </tr>
                      <tr className="">
                        <td class="font-bold lg:py-4 py-8">Excellence:</td>
                        <td class="text-left">
                          We are committed to achieving the highest standards in
                          all our endeavors.
                        </td>
                      </tr>
                      <tr className="">
                        <td class="font-bold lg:py-4 py-8">Innovation:</td>
                        <td class="text-left">
                          We continuously explore new horizons and push the
                          boundaries of knowledge.
                        </td>
                      </tr>
                      <tr className="py-4">
                        <td class="font-bold pr-4 ">Collaboration:</td>
                        <td class="text-left">
                          We foster partnerships, share knowledge, and work
                          together for greater impact.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </About>
  );
};

export default AboutTitle;
