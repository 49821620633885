import React from "react";
import { Helmet } from "react-helmet";
import ServicesTitle from "../components/organisms/ServicesTitle";
import ServiceContent from "../components/organisms/ServicesContent";

const OurServicesPage = () => {
  return (
    <>
      <Helmet>
        <title>SSSRI - Services</title>
      </Helmet>
      <div className="">
        <div className="fixed w-full h-[76px] bg-blue-500 z-20"></div>
        <ServicesTitle />
        <div className="flex justify-center">
          <div className="mx-40">
            <div className=" text-primary uppercase lg:text-7xl text-4xl font-bold text-center m-20">
              Our Services
            </div>

            <ServiceContent />
          </div>
        </div>
      </div>
    </>
  );
};

export default OurServicesPage;
