import styled from "styled-components";
import BackgroundImage from "../../assets/images/backgrounds/services.webp";

const Services = styled.div`
  .header {
    background-image: url(${BackgroundImage});
    background-attachment: fixed;
    background-position: center;
    background-size: cover;
  }
  .text-primary {
    color: #351010;
  }
`;
const ServicesTitle = () => {
  return (
    <Services>
      <div className="header h-[60vh] flex items-center">
        <div className="w-full text-center">
          <p className="lg:text-8xl text-4xl font-extrabold text-primary uppercase px-10">
            Our Team of Professionals
          </p>
          <p className="lg:text-6xl text-3xl m-4 font-extralight">
            Always Ready, Always the Best
          </p>
        </div>
      </div>
    </Services>
  );
};

export default ServicesTitle;
